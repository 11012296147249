<template>
  <div id="onePage">
    <header>
      <top-title
      title="ONE PAGE"
      subTitle="一頁式網站">
      </top-title>
    </header>
    <div class="container">
      <page-main-body
        title="一頁式網頁5分鐘輕鬆打造你的需要"
        subTitle="One-page for 5 minutes to easily create your needs"
        paragraph1="我們是否常煩惱：我只想要簡單的 DM，還要花費請網頁設計嗎、這次展場讓人看一下產品目錄又不想印 DM、菜單時常更新總是要花錢印。沒錯！在數位化的時代，內容常常汰換更新，又不需要花大錢製作網站。"
        paragraph2="Fans Play Family - one page 正符合你的需要，既可讓你達到變化又可不需要花大手筆製作費，以下是我們為您介紹的 one page 的應用唷…">
      </page-main-body>
    <h2 style="margin-bottom:0">用途多廣，一次搞定!</h2>
    <h2 style="margin-top:0;font-weight:300">Multiple uses, done by once!</h2>
    <games-topic topic="索取優惠" 
    src="onepage/getDiscount" type="left"
    feature="一頁展示結合互動小遊戲"
    :applys="[
    '單次宣傳',
    '發送優惠',
    '體驗活動',
    '展場吸引人氣']"/>

    <games-topic topic="線上報名" 
    src="onepage/signInOnline" type="right"
    feature="結合表單選項"
    :applys="[
    '單次宣傳',
    '發送優惠',
    '體驗活動',
    '展場吸引人氣']"/>

    <games-topic topic="菜單閱讀" 
    src="onepage/menuRead" type="left"
    feature="呈現菜單價目表"
    :applys="[
    '主題菜單宣傳',
    '固定菜單放置 Qr Code 供客人閱讀',
    '展現特定大廚製作流程',
    '可供排隊人潮先行閱讀',
    '線上宣傳菜單插入地圖導線下']"/>

    <games-topic topic="線上訂位" 
    src="onepage/regestOnline" type="right"
    feature="結合優惠 + 表單"
    :applys="[
    '特定活動線上宣傳先訂位',
    '結合優惠票券線下使用',
    '個人服務訂位場次時間選擇']"/>

    <games-topic topic="產品目錄" 
    src="onepage/productTypes" type="left"
    feature="主題式產品宣傳"
    :applys="[
    '百貨專櫃、實體門市新品、會員主題頁',
    '提供逛街人潮掃描目錄閱覽',
    '成為線上導流節慶、主題綜合頁']"/>

    <games-topic topic="食記參閱" 
    src="onepage/foodBlog" type="right"
    feature="加入影音、照片成為部落格"
    :applys="[
    '可供客戶參閱主打美食文章',
    '適合需要服務員詳細解說時使用',
    '適合逛街時客人於店內掃 Qr Code 參閱']"/>

    <games-topic topic="旅遊方案" 
    src="onepage/tripMethod" type="left"
    feature="呈現一系列方案類別"
    :applys="[
    '可用於旅遊展時取代 DM 使用',
    '可結合優惠券 + DM 呈現',
    '可作為線上廣告旅遊方案廣告頁']"/>
    </div>
  </div>
</template>

<script>
import topTitle from "@/components/topTitle.vue";
import pageMainBody from "@/components/pageMainBody.vue";
import gamesTopic from "@/components/gamesTopic.vue";

export default {
  name: "onePage",
  components: {
    topTitle,
    pageMainBody,
    gamesTopic
  },
  destroyed() {
    this.$parent.isChildPage = false;
  },
  mounted() {
    this.$parent.isChildPage = true;
  }
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/style.scss";
header {
  background-image: url("../../assets/image/products/onePage/topBanner.jpg");
}

#onePage {
  display: flex;
  flex-direction: column;
  align-items: center;
  h2,
  h3 {
    margin: 4% 0;
  }
  h2 {
    @include font(large);
  }
  h3 {
    @include font(medium);
  }
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    .gamesTopic {
      margin: 4% 0;
      width: 80%;
      &.left {
        align-self: flex-start;
      }
      &.right {
        align-self: flex-end;
      }
      @media #{$tablet} {
        align-self: center !important;
      }
    }
  }
}
</style>
