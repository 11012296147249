<template>
  <div class="gamesTopic" :class="[type]">
    <div class="holloFrame" v-if="!isShow"></div>
    <div class="video" v-if="type == 'left' || isTablet">
      <div class="mask"><i class="far fa-play-circle" @click="playVid"></i></div>
      <transition name="slideLeft">
      <video v-if="isShow" :src="`${baseUrl}static/video/${src}.mp4`" autoplay @click="vidControll" muted loop></video>
      </transition>
    </div>
    <transition name="opaFadeIn">
    <template v-if="isShow">
    <div class="text">
      <div class="topic">{{topic}}
        <hr>
      </div>
      <div class="feature">
        <span><i class="fas fa-star"></i> 特色</span>
        <ul>
          <li>{{feature}}</li>
        </ul>
      </div>
      <div class="apply">
        <span><i class="fas fa-star"></i> 建議用於</span>
        <ul>
          <li v-for="(item, key) in applys" :key="key">{{item}}</li>
        </ul>
      </div>
    </div>
    </template>
    </transition>
    <div class="video" v-if="type == 'right' && !isTablet">
      <div class="mask"><i class="far fa-play-circle" @click="playVid"></i></div>
      <transition name="slideRight">
      <video v-if="isShow" :src="`${baseUrl}static/video/${src}.mp4`" autoplay @click="vidControll" muted loop></video>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    topic: String,
    feature: String,
    applys: Array,
    src: String,
    type: String
  },
  data() {
    return {
      baseUrl: process.env.BASE_URL,
      isTablet: window.outerWidth <= 768 ? true : false,
      isMobile: window.outerWidth <= 480 ? true : false,
      isShow: false
    };
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("resize", this.handleResize);
  },
  mounted() {
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleScroll() {
      var st = window.pageYOffset || document.documentElement.scrollTop;
      var hook = window.innerHeight;
      if (st > this.$el.offsetTop - hook * 0.7) {
        this.isShow = true;
      }
    },
    handleResize() {
      this.isTablet = window.outerWidth <= 768 ? true : false;
      this.isMobile = window.outerWidth <= 480 ? true : false;
    },
    vidControll(e) {
      if (e.target.paused) {
        e.target.play();
        e.target.previousElementSibling.classList.remove("paused");
      } else {
        e.target.pause();
        e.target.previousElementSibling.classList.add("paused");
      }
    },
    playVid(e) {
      e.target.parentElement.nextElementSibling.play();
      e.target.parentElement.classList.remove("paused");
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/style.scss";
.slideLeft-enter-active,
.slideRight-enter-active {
  transition: 0.7s;
}
.slideLeft-enter,
.slideLeft-leave-to {
  transform: translateX(-8%);
}
.slideRight-enter,
.slideRight-leave-to {
  transform: translateX(8%);
}
.slideLeft-enter-to,
.slideRight-enter-to {
  transform: translate(0);
}

.gamesTopic {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  .holloFrame {
    height: 100vh;
  }
  .video {
    width: 38%;
    position: relative;
    margin: 0 4%;
    @media #{$tablet} {
      width: 80%;
    }
    video {
      width: 100%;
    }
    .mask {
      cursor: pointer;
      i {
        display: none;
        color: #474747;
      }
      &.paused {
        left: 17%;
        position: absolute;
        width: 66%;
        height: 100%;
        background-color: rgba(232, 232, 232, 0.5);
        border-radius: 15%;
        i {
          $iconSize: 80px;
          display: block;
          position: absolute;
          z-index: 1;
          font-size: $iconSize;
          top: calc(50% - #{$iconSize} / 2);
          left: calc(50% - #{$iconSize} / 2);
        }
      }
    }
  }
  .text {
    margin: 0 4%;
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 45%;
    @media #{$tablet} {
      width: 60%;
      margin: 4%;
    }
    @include font(smaller);
    .topic {
      text-align: center;
      @include font(medium);
    }
    hr {
      background-color: #707070;
      width: 100%;
      margin: 25% auto;
    }
    ul {
      padding-left: 6%;
    }
    .feature {
      width: 100%;
      ul {
        list-style: none;
      }
    }
    .apply {
      width: 100%;
      ul {
        list-style: none;
        li {
          margin: 0;
          margin-bottom: 1em;
          padding-left: 1.5em;
          position: relative;
          &:after {
            content: "";
            width: 10px;
            height: 10px;
            background: #707070;
            display: block;
            position: absolute;
            transform: rotate(45deg);
            top: calc(50% - 4px);
            left: 0;
          }
        }
      }
    }
  }
}
</style>
